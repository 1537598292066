export const chOrderLocale = {
  LOCAL: "到桌",
  AT_HOME: "外卖",
  TO_PICK_UP: "自取",
  badSettingOrder:
    "如果出现此消息，可能是由于您的订单配置错误，请按下更改订单类型按钮进行更正",
  scanQrIsRequiredForOrderType: "要下这种类型的订单，您必须扫描桌子上的QR码",
  phoneIsRequiredForOrderType: "要进行这种类型的订单，我们需要您先配置您的电话",
  authIsRequiredForOrderType: "要进行这种类型的订单，我们需要您登录",
  finishOrder: "完成订单",
  selectAddress: "选择地址",
  changeAddress: "更改地址",
  domicileSupplement: "送货附加费",
  minimumAmount: "最低金额",
};
