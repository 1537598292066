export const chInputsLocale = {
  email: "电子邮件",
  placeholderEmail: "您的电子邮件",
  password: "密码",
  placeholderPassword: "您的密码",
  name: "名字",
  placeholderName: "您的名字",
  lastName: "姓氏",
  placeholderLastName: "您的姓氏",
  phone: "电话",
  placeholderPhone: "您的电话号码",
  newPassword: "新密码",
  placeholderNewPassword: "您的新密码",
  selectOrderType: "选择订单类型",
  changeOrderType: "更改订单类型",
  notAllowedTableOrder: "Not allowed table orders",
};
