export const enInputsLocale = {
  email: "Email",
  placeholderEmail: "Your Email",
  password: "Password",
  placeholderPassword: "Your Password",
  name: "Name",
  placeholderName: "Your Name",
  lastName: "Last Name",
  placeholderLastName: "Your Last Name",
  phone: "Phone",
  placeholderPhone: "Your Number Phone",
  newPassword: "New Password",
  placeholderNewPassword: "Your New Password",
  selectOrderType: "Select Order Type",
  changeOrderType: "Change Order Type",
  notAllowedTableOrder: "Not allowed table orders",
};
