export const enFinishOrder = {
  address: "Address",
  amountCart: "Cart",
  amountTip: "Tip",
  back: "Back",
  continue: "Continue",
  confirmOrder: "Confirm Order",
  productsQty: "Products Qty",
  totalToPay: "Total to pay",
};
