import { axiosInstance } from "@/core";
import {
  buildUrl,
  getConfigWithHeaderAuthorizationDefault,
  addAccessTokenToHeaders,
} from "@/helpers";

import { mapperOrderTypeEndpoint } from "@/adapters";
import { orderTypeName } from "@/store/modules/orderType/constNames";

const configForOrderLocal = getConfigWithHeaderAuthorizationDefault;
const config = addAccessTokenToHeaders;

export const cartService = {
  sendOrder: async (order) => {
    const { type } = order;
    const configAxios =
      type === orderTypeName.LOCAL.toLowerCase() ? configForOrderLocal : config;
    return await axiosInstance.post(
      buildUrl(`requests/${mapperOrderTypeEndpoint[type]}`),
      order,
      configAxios()
    );
  },
};
