import { inputValidators } from "./inputValidators";

const {
  email,
  isRequired,
  maxLength,
  minLength,
  notEmoticons,
  strongPassword,
  positiveNumbers,
  rangeNumber,
} = inputValidators;

export const rulesGenerics = [isRequired, notEmoticons];

export const rulesEmail = [isRequired, email, notEmoticons];

export const rulesLetters = [notEmoticons];

export const rulesName = (value) => [
  isRequired,
  minLength(value, 3),
  maxLength(value, 80),
];

export const rulesPassword = [
  isRequired,
  minLength,
  notEmoticons,
  strongPassword,
];

export const rulesTipAmount = [
  isRequired,
  positiveNumbers,
  (value) => rangeNumber(value),
];

export const rulesPhone = [notEmoticons];
