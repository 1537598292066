<template>
  <section>
    <slot name="custom-activator" :events="{ handleOpenDialog }" />
    <v-dialog v-model="dialog" width="500">
      <template #activator="{ on, attrs }" v-if="!customActivator">
        <base-btn color="success" dark v-bind="attrs" v-on="on" :block="block">
          {{ labelBtnActivator }}
        </base-btn>
      </template>

      <v-card class="card__container">
        <v-card-title
          class="blue-grey darken-4 blue-grey--text text--lighten-4 justify-space-between"
        >
          <h3 class="text-h5">{{ title }}</h3>
          <v-btn @click="handleCloseDialog" icon color="primary" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-5 px-1">
          <slot name="content" :events="{ dialog }" />
        </v-card-text>
        <v-card-actions class="pb-5 flex-wrap justify-space-between">
          <slot name="actions" :events="{ handleCloseDialog }" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
export default {
  name: "BaseDialog",
  props: {
    title: { type: String, required: true },
    labelBtnActivator: { type: String },
    block: { type: Boolean, default: () => false },
    customActivator: { type: Boolean, default: () => false },
  },
  data() {
    return {
      dialog: false,
    };
  },
  provide() {
    return {
      handleCloseDialog: this.handleCloseDialog,
    };
  },
  methods: {
    handleOpenDialog() {
      this.dialog = true;
    },
    handleCloseDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.card__container {
  background: #f3f4f6;
}
</style>
